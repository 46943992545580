define(['app', '$window', 'accessibilityFocusHelper'], (app, $window, accessibilityFocusHelper) => {

  const facetDropdown = () => {
    const component = {};

    const _config = {
      selectors: {
        brandFacet: '.brandGroup_facetValue',
        facet: '[data-facet-filter]',
        content: '.facetDropdown_content',
        dropdownButton: '.facetDropdown_button',
        dropdownName: '.facetDropdown_name',
        chevronDown: '.facetDropdown_chevronDown',
        chevronUp: '.facetDropdown_chevronUp',
        closeButton: '.facetDropdown_closeButton',
        clearAll: '.facetDropdown_clearAllButton',
        clearAllText: '.facetDropdown_clear',
        closeText: '.facetDropdown_closeButton_closeText',
        applyText: '.facetDropdown_closeButton_applyText',
        selectedCount: '.facetDropdown_selectedCount',
        selectionCount: '.facetDropdown_selectionCount',
        listPage: '.responsiveProductListPage',
        sortByItem: '.sortFilters_filter'
      },
      attrs: {
        key: 'data-facet-key',
        value: 'data-facet-value',
        brands: 'data-brands',
        price: 'data-price',
        selected: 'data-selected',
        index: 'data-index',
        state: 'data-state',
        open: 'data-open',
        edge: 'data-edge',
        click: 'data-clicked',
        active: 'data-active',
        sortFilters: 'data-sort-filters',
        ariaChecked: 'aria-checked',
        ariaExpanded: 'aria-expanded',
        category: 'data-facet-category',
        focusedFacetCategory: 'data-focused-facet-category'
      },
      LSkeys: {
        sortByLabelValue: 'sortByLabelValue'
      },
      channels: {
        update: 'horizontalFacets/update',
        clearBrandsFacetSelections: 'brandsFacet/close',
        clearPriceFacetSelections: 'priceFacet/reset',
        close: 'horizontalFacets/close',
        dropdownClose: 'facetDropdown/close',
        brandGroup: 'brandsFacet/group',
        click: 'facetDropdown/click',
        priceFacet: 'facetDropdown/priceFacet',
        open: 'horizontalFacets/open'
      }
    };

    const GA_EVENT_CATEGORY = 'Facet Engagement';
    const GA_EVENT_ACTION_OPEN = 'Opens';
    const GA_EVENT_ACTION_CLOSE = 'Closes';
    const GA_EVENT_ACTION_ADD = 'Adds';
    const GA_EVENT_ACTION_REMOVE = 'Removes';
    const GA_EVENT_ACTION_SELECT = 'Select All';
    const GA_EVENT_ACTION_CLEAR = 'Clear All';

    const MAX_ALLOWED_DROPDOWNS_DESKTOP = 6;
    const MAX_ALLOWED_DROPDOWNS_TABLET = 4;

    const _init = (element) => {
      component.element = element;
      component.index = component.element.getAttribute(component.config.attrs.index);
      component.facetContent = component.element.querySelector(component.config.selectors.content);
      component.facetFilters = Array.from(component.element.querySelectorAll(component.config.selectors.facet));
      component.dropdownButton = component.element.querySelector(component.config.selectors.dropdownButton);
      component.dropdownName = component.element.querySelector(component.config.selectors.dropdownName);
      component.sortByItem = Array.from(component.element.querySelectorAll(component.config.selectors.sortByItem));
      component.chevronDown = component.element.querySelector(component.config.selectors.chevronDown);
      component.chevronUp = component.element.querySelector(component.config.selectors.chevronUp);
      component.closeButton = component.element.querySelector(component.config.selectors.closeButton);
      component.clearAllButton = component.element.querySelector(component.config.selectors.clearAll);
      component.selectedCountText = component.element.querySelector(component.config.selectors.selectedCount);
      component.clearAllText = component.element.querySelector(component.config.selectors.clearAllText);
      component.closeText = component.element.querySelector(component.config.selectors.closeText);
      component.applyText = component.element.querySelector(component.config.selectors.applyText);
      component.selectionCount = component.element.querySelector(component.config.selectors.selectionCount);
      component.sortFilters = component.element.getAttribute(component.config.attrs.sortFilters);
      component.category = component.element.getAttribute(component.config.attrs.category);
      component.listPageWrapper = document.querySelector(component.config.selectors.listPage);
      component.priceFacet = component.element.getAttribute(component.config.attrs.price);

      if (component.listPageWrapper.hasAttribute(component.config.attrs.focusedFacetCategory)) {
        component.setFocusAndOpen();
      }

      component.addEventListeners();
      if (component.sortFilters === 'false') {
        component.setSelectedCount();
        component.subscribe();
        component.changeAlignment();
      }

      if (component.sortFilters === 'true') {
        component.subscribe();
        component.updateSortLabel;
      }

      return component;
    };

    const _subscribe = () => {
      if (app.controlTower.topics[component.config.channels.close] === undefined) {
        app.subscribe(component.config.channels.close, component.closeDropdownAndUpdate);
      }

      if (component.element.getAttribute(component.config.attrs.brands) === 'true') {
        app.clear(component.config.channels.brandGroup);
        app.subscribe(component.config.channels.brandGroup, component.updateStateOfSelectionButton);
      }
    };

    const _setSelectedCount = () => {
      if (component.selectionCount.classList.contains('show')) {
        component.selectedCount = parseInt(component.selectionCount.innerText);
      } else {
        component.selectedCount = 0;
      }
    };

    const _addEventListeners = () => {
      component.dropdownButton.addEventListener('click', component.triggerDropdown);

      if (component.sortFilters === 'false') {
        component.closeButton.addEventListener('click', component.closeDropdownAndUpdate);
        component.clearAllButton.addEventListener('click', component.clearAll);

        for (let facet of component.facetFilters) {
          facet.addEventListener('click', component.facetClick.bind(null, facet));
          facet.addEventListener('keydown', component.triggerFacetClick.bind(this, facet));
        }

        $window.addEventListener('resize', component.changeAlignment);
      }

      if (component.sortFilters === 'true') {
        for (let sortFilter of component.sortByItem) {
          sortFilter.addEventListener('click', component.updateSortLabel);
          sortFilter.addEventListener('keydown', component.triggerSortFilters);
        }
      }
    };

    const _triggerSortFilters = (e) => {
      if (e.key === ' ' || e.key === 'Enter') {
        e.preventDefault();
        component.updateSortLabel(e);
      }
    };

    const _updateSortLabel = (e) => {
      const sortFilter = e && e.target ? e.target : e;
      if (sortFilter) {
      const sortDropdownItem = component.dropdownName;
      sortDropdownItem.textContent = sortFilter.innerText;
      $window.localStorage.setItem(component.config.LSkeys.sortByLabelValue, sortDropdownItem.textContent);
      }
    };

    component.updateSortLabel = _updateSortLabel;

    const _changeAlignment = () => {
      component.resetAlignment();
      let index = parseInt(component.index) + 1;
      let divider = component.getMaxAllowedFacetsPerRow();

      if (index % divider === 0) {
        component.facetContent.setAttribute(component.config.attrs.edge, '')
      }
    };

    const _resetAlignment = () => {
      if (component.facetContent.hasAttribute(component.config.attrs.edge)) {
        component.facetContent.removeAttribute(component.config.attrs.edge);
      }
    };

    const _build = (selectAll) => {
      component.facets = [];
      let facetFilters = component.facetFilters;
      if (component.element.hasAttribute(component.config.attrs.active)) {
        facetFilters = component.brandFilters;
      }

      component.facets = Array.from(facetFilters).map(facetFilter => {
        if (selectAll && facetFilter.hasAttribute(component.config.attrs.click)) {
          return null;
        }

        if (selectAll) {
          facetFilter.setAttribute(component.config.attrs.click, '');
        } else {
          facetFilter.removeAttribute(component.config.attrs.click);
        }

        const key = facetFilter.getAttribute(component.config.attrs.key);
        const value = facetFilter.getAttribute(component.config.attrs.value);

        return `${key}:${value}`;
      }).filter(f => f !== null);
    };

    const _triggerDropdown = () => {
      let index = component.closeDropdownAndUpdate();

      if (index && index !== parseInt(component.facetContent.getAttribute(component.config.attrs.index))) {
        app.publish('tracking/record', GA_EVENT_CATEGORY, `${GA_EVENT_ACTION_OPEN} ${component.category}`);
        component.listPageWrapper.setAttribute(component.config.attrs.focusedFacetCategory, component.category);
        component.facetContent.classList.toggle('show');
        component.toggleChevron(component.chevronDown, component.chevronUp);
        component.toggleButtonState();
      }
    };

    const _closeDropdownAndUpdate = (event) => {
      let allFacetContents = document.querySelectorAll(component.config.selectors.content);
      let openDropdown = Array.from(allFacetContents)
        .filter(facetContent => {
          return facetContent.classList.contains('show');
        })[0];

      if (openDropdown) {
        if (event) {
          event.preventDefault();
        }
        if (component.shouldUpdate(openDropdown)) {
          component.triggerPageUpdate();
          return null;
        } else {
          return component.closeDropdown(openDropdown);
        }
      }

      return -1;
    };

    const _closeDropdown = (openDropdown) => {
      if (openDropdown.getAttribute('data-brands') === 'true') {
        app.publish(component.config.channels.clearBrandsFacetSelections);
      }

      if (openDropdown.getAttribute('data-price') === 'true') {
        app.publish(component.config.channels.clearPriceFacetSelections);
      }

      openDropdown.classList.toggle('show');
      let parent = openDropdown.parentNode;
      let category = parent.getAttribute(component.config.attrs.category);
      let chevronDown = parent.querySelector(component.config.selectors.chevronDown);
      let chevronUp = parent.querySelector(component.config.selectors.chevronUp);
      let button = parent.querySelector(component.config.selectors.dropdownButton);

      component.toggleButtonState(button);
      component.toggleChevron(chevronDown, chevronUp);
      app.publish('tracking/record', GA_EVENT_CATEGORY, `${GA_EVENT_ACTION_CLOSE} ${category}`);
      return parseInt(openDropdown.getAttribute(component.config.attrs.index));
    };

    const _shouldUpdate = (openDropdown) => {
      return Array.from(openDropdown.querySelectorAll(component.config.selectors.facet))
        .filter(filter => filter.hasAttribute(component.config.attrs.click)
          ^ filter.hasAttribute(component.config.attrs.selected)).length > 0;
    };

    const _toggleChevron = (chevronDown, chevronUp) => {
      if (chevronDown && chevronUp) {
        chevronDown.classList.toggle('hide');
        chevronUp.classList.toggle('hide');
      }
    };

    const _clearAll = () => {
      component.clearAllText.classList.toggle('hide');
      app.publish('tracking/record', GA_EVENT_CATEGORY, `${GA_EVENT_ACTION_CLEAR} ${component.category}`);
      if (component.element.getAttribute(component.config.attrs.price) === 'true') {
        app.publish(component.config.channels.priceFacet);
      } else {
        component.selectedCount = 0;
        component.updateSelectedCount(component.selectedCount);
        component.build();
        component.publishChanges(true);
        component.toggleCloseButtonText();
      }
    };

    const _selectAll = () => {
      app.publish('tracking/record', GA_EVENT_CATEGORY, `${GA_EVENT_ACTION_SELECT} ${component.category}`);
      component.selectedCount = component.facetFilters.length;
      component.updateSelectedCount(component.selectedCount);

      component.build(true);
      component.publishChanges(false);
    };

    const _publishChanges = (clearSelections) => {
      app.publish(component.config.channels.update, component.facets, clearSelections);
    };

    const _toggleButtonState = (button) => {
      if (button) {
        button.removeAttribute(component.config.attrs.open);
        component.dropdownButton.setAttribute(component.config.attrs.ariaExpanded, 'false');
      } else {
        component.dropdownButton.setAttribute(component.config.attrs.ariaExpanded, 'true');
        component.dropdownButton.setAttribute(component.config.attrs.open, '');
      }
    };

    const _triggerPageUpdate = () => {
      app.publish(component.config.channels.dropdownClose);
    };

    const _updateSelectedCount = (count) => {
      if (component.selectedCountText) {
        component.selectedCountText.innerText = count;
      }
    };

    const _facetClick = (facet) => {
      if (facet) {
        let facetValue = facet.getAttribute(component.config.attrs.value);
        let facetCategory = facet.getAttribute(component.config.attrs.category);
        let pushToArray = true;
        if (!facet.hasAttribute(component.config.attrs.click)) {
          facet.setAttribute(component.config.attrs.click, '');
          facet.setAttribute(component.config.attrs.ariaChecked, 'true');
          component.selectedCount++;
          app.publish('tracking/record', GA_EVENT_CATEGORY, `${GA_EVENT_ACTION_ADD} ${facetCategory}`, facetValue);
        } else {
          pushToArray = false;
          facet.removeAttribute(component.config.attrs.click);
          facet.setAttribute(component.config.attrs.ariaChecked, 'false');
          component.selectedCount--;
          app.publish('tracking/record', GA_EVENT_CATEGORY, `${GA_EVENT_ACTION_REMOVE} ${facetCategory}`, facetValue);
        }

        app.publish(component.config.channels.click, facet, pushToArray);
        component.updateSelectedCount(component.selectedCount);
        component.toggleButtonText();
      }
    };

    const _toggleButtonText = () => {
      component.toggleCloseButtonText();
      let hasChanges = component.hasChanges();
      if (hasChanges) {
        if (component.clearAllText.classList.contains('hide')) {
          component.clearAllText.classList.remove('hide')
        }
      } else {
        if (!component.clearAllText.classList.contains('hide')) {
          component.clearAllText.classList.add('hide')
        }
      }
    };

    const _updateStateOfSelectionButton = (brandGroup) => {
      if (brandGroup) {
        component.element.setAttribute(component.config.attrs.active, '');
        component.brandFilters = Array.from(brandGroup.querySelectorAll(component.config.selectors.facet));
      } else {
        if (component.element.hasAttribute(component.config.attrs.active)) {
          component.element.removeAttribute(component.config.attrs.active);
        }
      }
      component.toggleButtonText();
    };

    const _triggerFacetClick = (facet, e) => {
      if (e.key === ' ') {
        e.preventDefault();
        component.facetClick(facet);
      }
    };

    const _getMaxAllowedFacetsPerRow = () => {
      return $window.screen.width >= 1200 ? MAX_ALLOWED_DROPDOWNS_DESKTOP : MAX_ALLOWED_DROPDOWNS_TABLET;
    };

    const _setFocusAndOpen = () => {
      let openFacetCategory = component.listPageWrapper.getAttribute(component.config.attrs.focusedFacetCategory);

      if (openFacetCategory === component.category) {
        accessibilityFocusHelper.focus(component.dropdownButton);
        if (component.priceFacet === 'true') {
          component.triggerDropdown();
        }
      }
      if (component.sortFilters === 'true') {
        component.dropdownName.innerHTML = $window.localStorage.getItem(component.config.LSkeys.sortByLabelValue);
      }
    };

    const _toggleCloseButtonText = () => {
      let hasChanges = component.hasChanges(true);

      if (hasChanges) {
        if (!component.closeText.classList.contains('hide')) {
          component.closeText.classList.add('hide')
        }

        if (component.applyText.classList.contains('hide')) {
          component.applyText.classList.remove('hide')
        }
      } else {
        if (component.closeText.classList.contains('hide')) {
          component.closeText.classList.remove('hide')
        }

        if (!component.applyText.classList.contains('hide')) {
          component.applyText.classList.add('hide')
        }
      }
    };

    const _hasChanges = (closeButton) => {
      let facetFilters = component.facetFilters;
      if (component.element.hasAttribute('data-active')) {
        facetFilters = component.brandFilters;
      }

      if (closeButton) {
        return Array.from(facetFilters)
          .filter(facetFilter => facetFilter.hasAttribute('data-clicked')
            ^ facetFilter.hasAttribute('data-selected'))[0];
      }

      return Array.from(facetFilters).some(facetFilter => facetFilter.hasAttribute('data-clicked'));
    };

    component.config = _config;
    component.init = _init;
    component.build = _build;
    component.addEventListeners = _addEventListeners;
    component.triggerDropdown = _triggerDropdown;
    component.closeDropdown = _closeDropdown;
    component.subscribe = _subscribe;
    component.toggleChevron = _toggleChevron;
    component.toggleButtonText = _toggleButtonText;
    component.toggleButtonState = _toggleButtonState;
    component.clearAll = _clearAll;
    component.selectAll = _selectAll;
    component.publishChanges = _publishChanges;
    component.changeAlignment = _changeAlignment;
    component.resetAlignment = _resetAlignment;
    component.triggerPageUpdate = _triggerPageUpdate;
    component.updateSelectedCount = _updateSelectedCount;
    component.facetClick = _facetClick;
    component.setSelectedCount = _setSelectedCount;
    component.updateStateOfSelectionButton = _updateStateOfSelectionButton;
    component.triggerFacetClick = _triggerFacetClick;
    component.getMaxAllowedFacetsPerRow = _getMaxAllowedFacetsPerRow;
    component.setFocusAndOpen = _setFocusAndOpen;
    component.shouldUpdate = _shouldUpdate;
    component.closeDropdownAndUpdate = _closeDropdownAndUpdate;
    component.toggleCloseButtonText = _toggleCloseButtonText;
    component.hasChanges = _hasChanges;
    component.updateSortLabel = _updateSortLabel;
    component.triggerSortFilters = _triggerSortFilters;

    return component;
  };

  return facetDropdown;
});
